define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-llms-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model(params) {
      const allLlms = this.modelFor("adminPlugins.show.discourse-ai-llms");
      const id = parseInt(params.id, 10);
      const record = allLlms.findBy("id", id);
      record.provider_params = record.provider_params || {};
      return record;
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set("allLlms", this.modelFor("adminPlugins.show.discourse-ai-llms"));
    }
  });
});